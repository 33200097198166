.custom-article-card2 {
    width: 100%;
    cursor: pointer;
    border: 1px dashed #323248;
    background: #151521 0% 0% no-repeat padding-box;
}

.docx {
    width: 40px;
    height: 40px;
    background: #1c3238 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    align-content: center;
    justify-content: center;
    display: flex;
    padding: 5px;
}
.docx-l {
    width: 40px;
    height: 40px;
    background: #2f264f 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    align-content: center;
    justify-content: center;
    display: flex;
    padding: 5px;
}

.imgx-p {
    background: url("doc.svg");
    width: 100%;
    background-size: cover;
    height: 100%;
}
.imgx-l {
    background: url("doc2.svg");
    width: 100%;
    background-size: cover;
    height: 100%;
}

.title-article {
    text-align: left;
    font: normal normal normal 14px/28px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
